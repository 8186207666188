
// ---
// Typography
// ---

body {
	font-family: $body-font;
	line-height: $line-height-base;
	font-weight: $font-weight-body;
}

a,
a:visited,
a:link {
	color: $color-link;
	text-decoration: none;
}

a {
	&:hover, &:focus {
		color: $color-link-hover;
		background: none;
	}
	&:active {
		color: $color-link-active;
	}
}

h1, h2, h3, h4, h5, h6 {
	line-height: $line-height-base;
	font-family: $heading-font;
	margin-bottom: 0;
}

h1 {
	font-size: $size-h1;
	margin-top: $size-h3;
	line-height: 3.125rem;
	font-weight: $font-weight-body;
	letter-spacing: $letter-spacing;
}

h2 {
	font-size: $size-h2;
	margin-top: $size-h4;
	line-height: 1.875rem;
	font-weight: $font-weight-headings;
		letter-spacing: $letter-spacing;
}

h3 {
	font-size: $size-h3;
	line-height: 1.75rem;
	margin-top: $size-h4;
		letter-spacing: $letter-spacing;
}

h4 {
	font-size: $size-h4;
	margin-top: $size-base;
	font-weight: $font-weight-headings;
		letter-spacing: $letter-spacing;
}

h5 {
	font-size: $size-h5;
	margin-top: $size-base;
}

h6 {
	font-size: $size-sml;
	margin-top: $size-base;
}

p, ol, ul {
	margin-top: $size-base;
	font-weight: $font-weight-body;
	line-height: 1.625rem;
	font-size: $size-base;
}


small {
	font-size: $size-sml;
}
