.nav-main {
	transition: all 0.75s ease;

	&.active {
		max-height: $screen-md;
	}

	@include bp(md) {

	}
}

.nav-items {
	list-style-type: none;
	padding-left: 0;

	@include bp(md) {
		display: flex;
		flex-wrap: wrap;
		& > li {
		}
	}
}

	
