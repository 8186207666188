// ----
//
// Table of Contents
//
// Import all our partials here. Don't add styles to this file, keep everything separated into the partials - it helps to keep the code in reusable chunks.
//
// ----
//--
// 1. Import vendor (third party code).
// 	  We do this first because we may override some of these and they are not dependent on our code
//--
@import "vendor/normalize"; // ---
// MIXINS
// ---
// Uppercase, slightly letterspaced text. Could use for headings.
// Mainly a timesaver.
// Media Queries
// http://css-tricks.com/conditional-media-query-mixins/
// A breakpoint mixin is super useful for nesting within other selectors:
$MQs: true;
@mixin bp($point) {
  @if ($MQs) {
    // These screen sized map to the screen variables in _varibles.scss
    $bp-xs: "(max-width: #{$screen-xs})";
    $bp-sm: "(min-width: #{$screen-sm})";
    $bp-md: "(min-width: #{$screen-md})";
    $bp-lg: "(min-width: #{$screen-lg})";
    @if $point == xs {
      @media #{$bp-xs} {
        @content;
      }
    } @else
    if $point == sm {
      @media #{$bp-sm} {
        @content;
      }
    } @else
    if $point == md {
      @media #{$bp-md} {
        @content;
      }
    } @else
    if $point == lg {
      @media #{$bp-lg} {
        @content;
      }
    }
  }
}
@mixin upperspace() {
  text-transform: uppercase;
}
// Love me a clearfix.
@mixin clearfix() {
  &:after,
  &:before {
    content: " "; // 1
    display: table; // 2
  }

  &:after {
    clear: both;
  }
}
// ---
// Screen sizes
// ---
// Read this NOW:
// http://bradfrostweb.com/blog/post/7-habits-of-highly-effective-media-queries/
// "Start with the small screen first, then expand until it looks like shit. Time for a breakpoint!"
// -Stephen Hay
$screen-xs: 28em;
$screen-sm: 35em;
$screen-md: 52em;
$screen-lg: 66em;
$screen-xl: 80em; // ---
// Colors
// ---
// These are names for actual colors. Below, you'll see how to name them for use.
// Aw man, I love Kuler:
// http://adobe.ly/1wwZ0Rk
$dark-blue: #334D5C;
$teal: #45B29D;
$yellow: #EFC94C;
$orange: #E27A3F;
$red: #DF4949; // In art school I was told never to make white pure white and black pure black - not sure if that holds up for the world wide web, but maybe!
$white: #fefefe;
$black: #1F232A; // STOP!!! READ THIS:
// Semantic Color Names
// http://thesassway.com/beginner/variable-naming
// These are nice utility colors
$color-accent-light: $yellow;
$color-accent-med: $teal;
$color-accent-dark: $dark-blue;
$color-text-light: $white;
$color-text-dark: $black;
$color-notice-success: $teal;
$color-notice-info: lighten($dark-blue, 20%);
$color-notice-danger: $red;
$color-notice-warning: $orange; // Then element-based names are nice too
$color-link: $color-accent-med;
$color-link-hover: darken($color-link, 20%);
$color-link-active: darken($color-link, 60%); // The most commonly rotated color:
$color-rotate-text: $color-text-light;
$color-rotate-bkgd: $color-accent-med;
$color-rotate-link: $color-accent-light;
$color-accent-border: 1px dotted $color-accent-med; // ---
// Scale
// ---
// Use these values for font sizes, margins, padding, everything! It's nice to use consistent values instead of magic numbers (http://j.mp/smellycss).
// Also, rems are awesome. Here's an article about them:
// http://snook.ca/archives/html_and_css/font-size-with-rem
// And a small pen I made that explains them: http://codepen.io/laras126/pen/YyWLgz
$size-base: 1rem;
$size-sml: $size-base*0.75;
$size-h1: $size-base*2.5;
$size-h2: $size-base*1.5;
$size-h2-home: $size-base*3.2;
$size-h3: $size-base*1.125;
$size-h4: $size-base*1;
$size-h5: $size-base*1;
$size-h6: $size-sml; // Fonts
$letter-spacing: 0.5px;
$sans-serif: "Open Sans", -apple-system, “Roboto”, Helvetica, Arial, sans-serif;
$serif: "Playfair Display", -apple-system, “Roboto”, Georgia, serif;
$font-weight-body: 300;
$font-weight-headings: 500;
//Acumin Pro Medium is 500
$font-weight-extrabold: 800;
$font-weight-bold: 500;
$line-height-base: 1.4;
$heading-font: $serif;
$body-font: $sans-serif;
$content-padding: 1em; //--
// 3. Import our actual styles.
//    See the files for descriptions of their contents. You can add any other partials you see fit - for example, WordPress might have a _posts.scss partial.
//--
@import "partials/typography";
@import "partials/buttons";
@import "partials/forms";
@import "partials/nav";
@import "partials/base";
$light: #F4F4F4 !default;
$dark: #444 !default;
$evergreen: #37D4A8;
$santa-red: #DA1647;
$silver-bells: #a6a6a6;
$scorpion: #595959;
$tundora: #444;
$mine-shaft: #363636;
$link-red-bg: #f3f6fa;
/**  WATERS Colors **/
$medium-gray: #383838;
$eggplant: #32322E;
$shamrock: #1CA1A5;
$plum: #2D2A42;  //Footer,
$pine-green: #17686A;
$carrot: #EE7700;
$pumpkin: #D16A2B;
$ocean-blue: #58B8EE;
$salmon-red: #F11B3F;
$smoke: #F2F2F2;
$ash: #DADADA;
$pewter: #777777;
$charcoal: #383834;
$dark-charcoal: #32322E;
$blue-ice: #15B1B6;
$facebook-blue: #3B5998;
$twitter-blue: #0084b4;

/* =================================
  BFC Colors
==================================== */

$foggy-ocean: #D1DCE1;
$deep-ocean: #4276A7;

/* End BFC Colors  ================== */


body {
  background: $white;
  -webkit-font-smoothing: antialiased;
  /*display: flex;*/
}

.container {
  display: grid;
  grid-template-areas: "header" "main-content" "footer";
  // background: url(/wp-content/uploads/2018/11/header-trees.jpg) no-repeat;
}

.container > * {}
/* =================================
  HEADER
==================================== */

header .container {
  background: rgba($black, .8);
  @include bp(md) { background: none;}
}

header { background: $charcoal;}

header.home {
  grid-area: header;
  background:  url(../img/footer-bg3.jpg) no-repeat center center;

  @include bp(xs) {}
  @include bp(md) {
    padding: 1rem 0;
    min-height: 350px;
  }
}

.identity {
  margin-top: 0;
}

.identity h1, .identity h3 {
  font-family: $sans-serif;
}

.identity svg {
  max-width: 100px;
  fill: $light;
}

.identity h1 {
  font-size: 1.4rem;
  margin-top: 0px;
  font-weight: bold;
}
.identity h1 a { color: $white; }

.identity h3 {font-size: 1rem; margin-top: 0; }

header .wrapper {
  @include bp(xs) {}
  @include bp(sm) {}
  @include bp(md) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include bp(lg) {
    flex-direction: row;
    justify-content: space-between;
  }
}

header .identity {
  text-align: center;
  @include bp(lg) {
    text-align: left;
  }
}

.identity h1 {}

.identity h3 {
  color: $white;
}
//
// .show-menu {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   @include bp(md) {
//     display: none;
//   }
// }
//
// [role="banner"] input[type="checkbox"] {
//   -webkit-appearance: none;
//   display: none;
// }

nav a {
  text-align: center;
  display: block;
  padding: 10px 15px;
}

nav ul {
  list-style: none;
  width: 100%;
  padding: 0;
  @include bp(md) {
    display: flex;
  }
}

nav ul li {
  text-align: center;
}

.nav-main ul li a {
  color: $white;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 0.9em;
  -webkit-transition: background-color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}

.nav-main ul li a:hover {
  color: $white;
  background: rgba($santa-red, .7);
}

header.home .blast {
  margin: 0 auto;
  @include bp(xs) {}
  @include bp(sm) {}
  @include bp(md) {
    font-size: 80px;
    background: none;
  }
}
header.home .blast h1 {
  color: $white;
  font-size: 26px;
  text-align: center;
  @include bp(xs) {}
  @include bp(sm) {}
  @include bp(md) {font-size: 80px; }
}

header.home .blast h2::before { padding: 1em;border-top: 5px solid $santa-red; background: red; height: 5px;}
header.home .blast h2 {
  color: $white;
  text-align: center;
  font-size: 16px;
  padding: 0;
  margin: 0;
  @include bp(xs) {}
  @include bp(sm) {}
  @include bp(md) {
      font-size: 24px;
      padding-top: 2em;
  }

}
/* End Header ===================== */

//
// .site-main {
//     margin: auto;
//     max-width: 70em;
//     display: flex;
//     flex-direction: column;
//     @include bp(xs) {
//         width: 95%;
//         flex-direction: column;
//     }
//     @include bp(md) {
//         flex-direction: row;
//
//     }
//     @include bp(md) {
//
//     }
// }

/* =================================
  Headings, Fonts, Typography
==================================== */

h1 {
  color: $eggplant;
  font-weight: $font-weight-headings;
  font-size: $size-h1;
  @include bp(xs) {
    line-height: 2.5rem;
  }
}

h2 {
  color: $eggplant;
  font-weight: $font-weight-headings;
  font-size: $size-h2;
  @include bp(xs) {}
}

.home .article-content h2, .home article.blog > h2 {
  font-weight: $font-weight-headings;
  font-size: $size-h2-home;
  line-height: 4rem;
  margin-bottom: 2.5rem;
}

h3 {
  color: $eggplant;
  font-weight: $font-weight-headings;
  font-size: $size-h3;
  @include bp(xs) {}
}

.site-main a {
  background-color: transparent;
  color: inherit;
  box-shadow: inset 0 -4px 0 rgba($teal, .65);
  border-bottom: 3px solid rgba($teal, .65);
  text-decoration: none;
  -webkit-transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1);
  -o-transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1);
  transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1);
  border-bottom: none;
}

.site-main a:hover {
  background-color: rgba($teal, .65);

}

/* End Headings, Fonts, Typograph ===================== */

/* =================================
  MAIN CONTENT
==================================== */
main {
  grid-area: main-content;
  // border: 1px solid blue;
}

header .wrapper,
main .wrapper {
  max-width: $screen-xl;
}
.content-wrapper {
  padding: 0 1.5rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
  grid-gap: 20px;
}

.single {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  @include bp(xs) {}
  @include bp(md) {
    grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
  }
}

.section-1 {
  grid-column: 1 / -1;
  grid-gap: 20px;
  display: grid;
  grid-template-columns: 1fr;
  @include bp(xs) {}
  @include bp(sm) {}
  @include bp(md) {
    grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
  }
}
.section-1 .intro-content h2 { font-size: 1.7em; }
.section-1 .intro-content {
  align-self: center;
  @include bp(xs) {}
  @include bp(sm) {}
  @include bp(md) {
     grid-column: 1 / 5;
  }
}

.section-1 .quick-info {
  display: grid;
  grid-template-columns: 1fr;
  @include bp(xs) {}
  @include bp(sm) {}
  @include bp(lg) {
    grid-template-columns: 1fr 1fr;
     grid-column: 6 / -1;
  }
}


.section-1 .quick-info > h3 {
  grid-column: 1 / -1;
}

.our-farm {
  grid-column: 1 / -1;
  display: grid;
  grid-gap: 20px;
  @include bp(xs) { grid-template-columns: 1fr;}
  @include bp(sm) {}
  @include bp(md) {
    grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
  }
}
.our-farm .about-content {
    @include bp(md) {
      grid-column: 1 / 4;
    }
  }
.our-farm > h2 {
    @include bp(md) {
      grid-column: 1 / -1;
    }
  }
.our-farm > p { grid-column: 1 / 5; align-self: center;}
.our-farm > img {
  grid-column: 1 / -1;
  @include bp(md) {
    grid-column: 5 / -1;
  }
}

.our-trees {
  grid-column: 1 / -1;
  display: grid;
  grid-gap: 20px;
  @include bp(xs) { grid-template-columns: 1fr;}
  @include bp(sm) {}
  @include bp(md) {
      grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr 1fr 2fr;
  }
}

.our-trees > h2, .our-trees > h4 {
    @include bp(md) {
      grid-column: 1 / -1;
    }
  }

  .our-trees > img {
    grid-column: 1 / -1;
    @include bp(md) {
      grid-column: 1 / 4;
    }
  }

  .our-trees .trees-content {
    grid-column: 1 / -1;
    @include bp(xs) { }
    @include bp(sm) {}
    @include bp(md) {
      grid-column: 5 / -1;
    }
  }

.tree-listings {
  display: grid;
  grid-template-columns: 1fr 1fr;
}


article.post-type-page {

  @include bp(xs) { }
  @include bp(sm) {}
  @include bp(md) {
    grid-column: 1 / 6;
  }

}
.content-wrapper ol, .content-wrapper ul {

    margin: 1em 0;

    @include bp(lg) {
      padding: inherit;
      padding: 0;
    }

}

.content-wrapper ol li,
.content-wrapper ul li {
  margin-bottom: 0.5rem;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}
/* =================================
   Home Page Specific Styling
==================================== */

// .wrapper article.tease-post {
//   border: 1px solid $ash;
// }

.wrapper article.tease-post h2 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: $font-weight-body;
  padding-top: 0;
  margin-top: 0;
}

.home article.blog .wrapper .tease-post { overflow: visible;}
.home article.blog .wrapper .tease-post img + h2, .blog-container .tease-post img + h2 {
  position: relative;
  margin-top: -30px;
  width: 90%;
}

.wrapper article.intro {
  display: grid;
  @include bp(md) {
    grid-template-columns: repeat(7, 1fr);
    margin: 0;
    padding: 0;
  }


}

article.intro figure {
    display: none;
    @include bp(xs) { }
    @include bp(md) {
      display: inherit;
      grid-column: 1 /-1;
      grid-row: 1 / -1;
      margin: 0;
    }

}

article.intro .intro-content {
  grid-column: 5 /-1;
  grid-row: 1 / -1;
  align-self: center;
}

article.intro .intro-content h2 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.3;
}


article.first {
  display: grid;
  @include bp(md) {
    grid-template-columns: 5fr 1fr 3fr;
    grid-gap: 20px;
    margin-bottom: 100px;
  }
}
article.first::after { }

article.first aside {
  grid-column: 3 / -1;
  align-self: center;
}

article.second {
  display: grid;
  @include bp(md) {
    grid-template-columns: 3fr 1fr 5fr;
    grid-gap: 20px;
  }
}
article.second aside {
  align-self: center;
}
article.second section {
  grid-column: 3 / -1;
}

// article.third, article.fourth {
//   display: grid;
//   @include bp(md) {
//     grid-template-columns: 5fr 1fr 3fr;
//     grid-gap: 20px;
//     margin-bottom: 100px;
//   }
// }
//
// article.third section, article.fourth section {
//   grid-column: 1 / 2;
// }


article.fourth section h3 {
  background: #ADD3D6;
  padding: .5rem;
  margin-bottom: 1rem;
  grid-column: 1 / 4;
}
/* --- Blog Styling - on homepage --- */
.home article.blog .wrapper {
  display: grid;
  @include bp(md) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  }
}

.home article.blog > h2 {
  font-size: $size-h2-home;
  margin-bottom: 3rem;
}
.home article.blog .wrapper {

}

.home article.blog .wrapper h2, .blog-container article.tease-post h2 {
  font-size:  $size-h2;
  font-family: $serif;
  font-weight: bold;
  background: $white;
  padding: 1rem;
  line-height: 1.35;
}
.home article.blog .wrapper p, .blog-container article.tease-post p {padding: 1rem; }

.home article.blog .wrapper article, .blog-container .tease-post {
  background: #F9F9F9;
  background: rgba(216,216,216,0.15);
  box-shadow: 0 0 10px 4px rgba(0,0,0,0.05);
  padding: 0;
}

.home article.blog .wrapper h2 a, .blog-container .tease-post h2 a {
  background-color: transparent;
  color: inherit;
  box-shadow: none;
  border-bottom: none;
  text-decoration: none;
  -webkit-transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1);
  -o-transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1);
  transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1);
  border-bottom: none;

 }

 .home article.blog .wrapper h2 a:hover, .blog-container .tease-post h2 a:hover {
    background: rgba($shamrock, .75);
 }

 .home .two-column {
   display: grid;
   grid-gap: 60px;
   grid-template-columns: 1fr;
   @include bp(md) {
    grid-template-columns: 1fr 1fr ;
   }
 }

 // .home .blog a.read-more {
 //   background: $shamrock;
 //   padding: .5rem 1rem;
 //   margin: 2rem 0 0 0;
 // }
//
// .wrapper article.tease-post:hover {
//   border: 1px solid $blue-ice;
//   box-shadow: 0 0 0 1px $blue-ice;
// }
//
// .section-1 {
//   display: grid;
//   grid-template-columns: repeat(1, 1fr);
//   @include bp(xs) {}
//   @include bp(sm) {}
//   @include bp(md) {grid-template-columns: 3fr 1fr;}
//   @include bp(lg) {}
//
// }
//
// .intro-content {
//   align-self: center;
// }
//
// .our-farm {
//   display: grid;
//   grid-gap: 20px;
//   grid-template-columns: repeat(1, 1fr);
//   @include bp(xs) {}
//   @include bp(sm) {}
//   @include bp(md) {grid-template-columns: 2fr 2fr;}
//   @include bp(lg) {}
//
// }


/* End Homepage Styling ===================== */

.main-container {}

figcaption {
  font-size: 0.8rem;
  margin: 0 1rem 2rem 0;
}
/* --- Article Styling --- */
.wrapper article {
  // border: 1px solid $silver-bells;
  margin: 1em 0;
  padding: $content-padding;
}

.wrapper article .blog-author {
  margin: 1.6rem 0;
}

/* =================================
   Blog Page Specific Styling
==================================== */

/* --- Blog Styling - on blog page --- */
.blog-container {
  display: grid;
  @include bp(md) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  }
}

.blog-container article:first-child {
    grid-column: 1 / -1;
    max-width: 60em;
}


.tease-post h2 {
  font-family: $serif;
}

h1.blog-title {
  font-size: 4em;
  line-height: 1.5em;
}

blockquote {
  background: $smoke;
  margin: 1.5em 10px;
  padding: 1em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: rgba($shamrock, .4);
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote:after {
  color: rgba($shamrock, .4);
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-left: 0.25em;
  vertical-align: -0.6em;
}

blockquote p {
  display: inline;
}
/*** Blog Single Post View ***/
.blog-content {
  display: grid;
  grid-gap: 10px 50px;
  @include bp(md) {
    grid-template-columns: 2fr 3fr 4fr 5fr 2fr;
  }

}

.blog-content h1 {
  background: rgba($shamrock, 1);
  color: #fff;
  z-index: 99;
  padding: 0.25em 0.5em;
  grid-column: 1 / -1;
  @include bp(md) {font-size: $size-h3; }
  @include bp(md) {
    font-size: $size-h1;
    background: rgba($shamrock, 0.8);
    grid-column: 1 / 6;
    grid-row: 1 / 2;
  }
}

.blog-content > * {
  grid-column: 1 / -1;
  @include bp(md) {
    grid-column: 2 / span 3;
  }

}

.blog-content > h4 {
  grid-column: 1 / -1;
  @include bp(md) {
    grid-column: 1 / span 2;
  }

}

.blog-content > figure {
  margin: 0;
  @include bp(md) {
    grid-column: 2 / -1;
    grid-row: 2 / 4;
  }

}

.site-main img {
  max-width: 100%;
}

/*** Single page views - simple grid ***/

// .article-content {
//   display: grid;
//   grid-template-columns: 5fr 2fr;
// }

.article-content h1 {
  grid-column: 1 / -1;
}


//.site-main { display: flex; flex-flow: row;}
//.has-sidebar { flex: 2 1 70%; background: rgba(0, 50, 255, 0.4);}
/* Pagination */
ul.pagination {
  list-style: none;
  display: flex;
}

ul.pagination li {
  margin: 0 0.4em;
}

ul.pagination li.current a {
  border-bottom: 2px solid $carrot;
}

ul.pagination li.disabled a {
  border: none;
}

ul.pagination li.prev:before {
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/0/02/SVG_logo.svg");
  background-repeat: no-repeat;
  background-size: 40px 40px;
  text-indent: 100%;
}

ul.pagination li a {
  color: $charcoal;
}
/* =================================
  Sidebar
==================================== */
aside.my-sidebar {
  margin-left: 2rem;
}

aside.my-sidebar h4 {
  margin-bottom: 0.5rem;
}

li.widget {
  list-style: none;
  margin-bottom: 2rem;
}

li.widget ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li.widget ul li {
  padding: 0.1em 0;
}

aside.my-sidebar input.search-field {
  border: 1px solid #DADADA;
  background: $white;
  padding: 0.5rem;
}

aside.my-sidebar input.search-submit {
  background: $shamrock;
  padding: 0.75rem 1rem;

  text-transform: uppercase;
  color: $white;
  border: none;
  margin-top: 1rem;
  font-size: 0.875rem;
}

aside.my-sidebar input.search-submit:hover {
  background: $pine-green;
  cursor: pointer;
}

/* =================================
  FOOTER
==================================== */
footer {
  grid-area: footer;
  background: linear-gradient(to bottom, rgba(147,147,147,1) 0%,rgba(147,147,147,0) 1%,rgba(147,147,147,0.62) 62%,rgba(255,255,255,1) 100%),
      /* bottom, image */
      url(../img/footer-bg3.jpg) repeat-x center;;
  padding: $content-padding;
  height: 500px;
}



// footer .wrapper {
//   max-width: $screen-xl;
//   @include bp(xs) {}
//   @include bp(sm) {}
//   @include bp(md) {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }
//   @include bp(lg) {
//     flex-direction: row;
//     justify-content: space-between;
//   }
// }

footer .wrapper {
  max-width: $screen-xl;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @include bp(xs) {}
  @include bp(sm) {}
  @include bp(md) {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
  @include bp(lg) {

  }
}

footer .footer-links {

}

.footer-links svg {
  width: 32px;
  fill: $light;
}
.footer-links.social {
    @include bp(md) {
      justify-self: end;
    }
}

.footer-links.social > p {
  @include bp(md) {
    text-align: center;
  }
}
.footer-links.social ul {
  display: flex;
  padding: 0;
}

.footer-links.social ul li {
  margin: 0 1rem;
}

.footer-links a.instagram svg:hover {
  width: 32px;
  fill: $santa-red;
}

.footer-links a.facebook svg:hover {
  width: 32px;
  fill: $facebook-blue;
}

.footer-links a.twitter svg:hover {
  width: 32px;
  fill: $twitter-blue;
}

footer h2 {
  grid-column: 1 / -1;
  font-family: $serif;
  color: $light;
  font-weight: 400;
  text-align: center;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  @include bp(xs) {}
  @include bp(sm) {}
  @include bp(md) {
    font-size: 3rem;
  }

}

footer p.callout {
  font-size: 1.2rem;
  grid-column: 1 / -1;
}

footer .center { text-align: center;}

footer .footer-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 2;
  box-sizing: border-box;
}

.footer-grid .footer-links {}

.footer-grid .copyright {}

footer .footer-links ul {
  list-style-type: none;
}

footer .wrapper li a {
  color: $link-red-bg;
}

footer .wrapper p > a {
  background-color: transparent;
  color: inherit;
  box-shadow: inset 0 -4px 0 rgba($evergreen, .65);
  border-bottom: 3px solid rgba($evergreen, .65);
  text-decoration: none;
  -webkit-transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1);
  -o-transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1);
  transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1);
  border-bottom: none;
}

footer .wrapper p > a:hover {
  background: rgba($evergreen, .5);
}

footer p {
  color: $light;
}

footer hr {
  margin-right: auto;
  border: 0;
  margin-left: auto;
  max-width: 80rem;
  border-bottom-color: rgba($dark, .2);
  border-bottom-style: solid;
  border-bottom-width: 4px;
  clear: both;
  display: block;
  margin: 2vh 0;
  padding: 0;
  height: 1px;
}
/* End Footer Styling ===================== */
